<template>
  <div class="edit-category">
    <error :errors="categoryError" />
    <category-form action="edit" />
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import categoryForm from "../../../components/category/categoryForm";
  import Error from "../../../partials/widgets/Error";
  import { mapGetters } from "vuex";
  export default {
    name: "index",
    components: { categoryForm, Error },
    computed: {
      ...mapGetters(['categoryError'])
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "تۈر باشقۇرۇش", route: "list" },
        { title: "ئۇچۇر تەھرىرلەش" }
      ]);
    },
    data(){
      return {};
    },
    methods: {
    }
  }
</script>

<style scoped>

</style>
